import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, APIHOST,  isLoggedIn, setCardNo, setMid, showLoading, updateloginStatus  } from '../../utils/gaia';
import Axios from 'axios';
const ScanQr = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPaused, setIspaused] = useState(false);
    const [showMember, setShowMember] = useState(false);
    const [canScan, setCanScan] = useState(true);
    const [member, setMember] = useState([])
    const [conversion, setConversion] = useState("")

    useEffect(()=>{
        //if(!isLoggedIn()){navigate(`/`)}
    },[])

    const scanResult = (result) =>{
        console.log(result)
        if (result.length > 0 && !isPaused) {
            setIspaused(true)
            checkQR(result[0]?.rawValue);
        }
    }


    const checkQR = (data) =>{
        if(data == ""){
          loadError("Please scan the qr code")
          setIspaused(false)
          return;
        }
        setLoading(true)
        setCanScan(false)
        showLoading("Verifying QR code, please wait...")
        Axios.post(`${APIHOST}kiosk/verifyQr`, {
            qr : data,
        }).then(function(res){
            let {data} = res;
            setLoading(false)
            loadSuccess(data.msg)
            setCardNo(data.cardno)
            setMid(data.mid)
            updateloginStatus(true)
            setTimeout(()=>{  window.location.href =  "/home" },1500)
        }).catch(function(er){
          setLoading(false)
          setTimeout(()=>setIspaused(false), 2000);
          if(typeof(er.response) !== "undefined"){
            if(er.response.status == 404 || er.response.status == 500){
              loadError(er.response.data.error ?? er.response.data.message)
            }else{
              loadError("A network error occured.")
            }
          }else{
            loadError(er.message)
          }
        })
    }

 
  return (
    <>
    <main class="common-bg">
        <section class="kiosk-login">
            <div class="nav-home">
                <a href="/"><img src={Images.moneybag} alt="" /></a>
            </div>
            <div class="common-center">
                <div class="container">
                    <div>
                        <div class="scanner-code-box">
                            <div class="qr-scaner-code">
                                <h2>SCAN QR CODE<br />TO CHECK YOUR BALANCE</h2>
                                <div>
                                    <Scanner scanDelay={3000} allowMultiple={true} paused={isPaused} onScan={(result) => scanResult(result)} constraints={{ facingMode: 'user' }} classNames={{container : "scanner-scan"}}  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  
    </>
  );
}

export default ScanQr;
