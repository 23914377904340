import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}

export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 2000,
    showConfirmButton: false
  });
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 200000,
    showConfirmButton: true
  })
}

export function actionError(msg,action,canCancel = false, cancelAction = null){
  PopSwal.fire({
    icon: 'danger',
    title: msg,
    showCancelButton: canCancel,
    confirmButtonColor: "#9b0032",
    confirmButtonText: "Retry",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      action()
    }else if(result.isDismissed){
      if(cancelAction == null){
        PopSwal.close()
      }else{
        cancelAction();
      }
    }
  });  
}

export function updateloginStatus(status = false,expires=1){
  Cookies.set("islogged", status, {path: "/", expires : expires});
}

export function setCardNo(code,expires=1){
  Cookies.set("oculus", code, {path: "/", expires : expires});
}

export function setMid(code,expires=1){
  Cookies.set("mid", code, {path: "/", expires : expires});
}


export function getCardNo(){
  return Cookies.get("oculus");
}

export function getMid(){
  return Cookies.get("mid");
}

export function isLoggedIn(){ 
  return Cookies.get("islogged"); 
}

export function destroySession(){
  Cookies.remove("mid",{ path: '/' });
  Cookies.remove("oculus",{ path: '/' });
  Cookies.remove("islogged",{ path: '/' });
}


let HOST = null;
let url = window.location.hostname;

if(url.includes("catchnshop.com")){
  HOST = "https://api.catchnshop.com";
}else{
  HOST = "http://127.0.0.1:9000";
  //HOST = "https://llapi.apacconnects.com";
}

export const APIHOST = `${HOST}/api/`
