import React, { useState,useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import {getToken, destroySession} from './utils/gaia';
import Axios from 'axios';

//v3.2
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.css';

// Import Main styles for this application
import './assets/css/fonts.css'
import './assets/css/style.css'

//import Main scaffolding
import {Home, ScanQr, NotFound} from './views/Pages';



Axios.defaults.headers.common['Authorization'] = "Bearer cv3KlBASXAaRFPVENWmxPBKolnSfi5Jn68afRDiWD4WQc4XZ918NUs2FwIvL";

Axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;  
}, function (error) {       
  if(typeof(error.response) !== "undefined"){
      switch (error.response.status) {
          case 503 :
            
              break
          case 401 :
              destroySession(); 
              window.location.replace("/")
              break
          default :
              break
      }
  }
  // Do something with response error
  return Promise.reject(error);
});

const App = () => {

  return (
    <>
    <BrowserRouter>   
      <Routes>
        <Route exact path="/home" name="Home" element={<Home />} />
        <Route exact path="/scan" name="Scan" element={<ScanQr />} />
        <Route exact path="/" name="Landing Page" element={<ScanQr />} />
        <Route exact path="*" name="Not Found" element={<NotFound />} />
      </Routes> 
    </BrowserRouter>
    </>
  );
}

export default App;
