export const Images = {
   loginbtn : require("../assets/images/Login.png"),
   voucher2 : require("../assets/images/5X VOUCHER 2.png"),
   voucher1 : require("../assets/images/5X VOUCHER 1.png"),
   moneybag : require("../assets/images/Graphic_Money bag.png"),
   homebtn : require("../assets/images/home.png"),
   submitbtn : require("../assets/images/submit.png"),
   btn2 : require("../assets/images/btn2.png"),
   btn1 : require("../assets/images/btn1.png"),
   generatebtn : require("../assets/images/generate btn.png"),
   donebtn : require("../assets/images/done.png"),
   grp3 : require("../assets/images/Group 3.png"),
   btnconvert : require("../assets/images/button_convert.png"),

   
}