import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { validEmail } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, APIHOST, isLoggedIn, showLoading, getMid, getCardNo, actionError, destroySession } from '../../utils/gaia';
import Axios from 'axios';
const Home = (props) => {

  const navigate = useNavigate();
  const [page, setPage] = useState("detail")
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [member, setMember] = useState([])
  const [rdm, setRdm] = useState(0)

  const timeoutDuration = 30000; // 30 secs
  let timeout;

  useEffect(()=>{
    if(!isLoggedIn()){navigate(`/`)}
    resetTimeout();
    loadData();
  },[])

  const redeem = () =>{
    if(member.type == "AI"){
        setPage("nonmember")
        return;
    }

    setLoading(true)
    showLoading("Converting Points, please wait...")
    Axios.post(`${APIHOST}kiosk/redeem`, {
        cardno : member.cardno,
    }).then(function(res){
        let {data} = res;
        loadSuccess(data.msg)
        setRdm(data.rdm);
        setPage("member")
        //setTimeout(()=>window.location.reload(), 2000)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          actionError(er.response.data.error ?? er.response.data.message, redeem, true, null)
        }else{
          loadError(er.response.data.error ?? er.response.data.message)
        }
      }else{
        loadError(er.message)
      }
      
    })
}

const resetTimeout = () => {
    // Clear the previous timeout
    clearTimeout(timeout);

    // Set a new timeout
    timeout = setTimeout(() => {
        // Action to take after inactivity, e.g., redirect to a timeout page
        //loadError("System inactivity detected.")
        destroySession();
        navigate("/")
    }, timeoutDuration);
}

// Add event listeners for different types of user activity
window.addEventListener('mousemove', resetTimeout);
window.addEventListener('keypress', resetTimeout);
window.addEventListener('mousedown', resetTimeout);
window.addEventListener('touchstart', resetTimeout);
window.addEventListener('scroll', resetTimeout);


const reset = () =>{
    destroySession();
    navigate("/")
}

  const loadData = () =>{
    setLoading(true)
    showLoading("Loading Details, please wait...")
    Axios.post(`${APIHOST}kiosk/details`, {
        qr : getCardNo(),
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setLoaded(true);
        loadSuccess(data.msg)
        setMember(data.member)
        resetTimeout();
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          actionError(er.response.data.error ?? er.response.data.message, loadData, true, reset)
        }else{
          loadError(er.response.data.error ?? er.response.data.message)
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

 
  return (
    <>
        <main class="common-bg">
            <section class="kiosk-bg-img" >
                <div class="nav-home">
                    <a onClick={reset}><img src={Images.homebtn} alt="" /></a>
                </div>
                <div class="common-center">
                <div class="container">
                        {page == "detail" && <div class="member-data">
                            <div class="member-box">
                                <div class="member-detail-box">
                                    <div class="member-id-box">
                                    <h4>MEMBER ID</h4>
                                    <p class="member-id">{member.mid}</p>
                                    </div>

                                    <div class="member-chances-box">
                                    <h4>CHANCES</h4>
                                    <p>{member.chances}</p>
                                    </div>

                                    <div class="member-point-box">
                                    <h4>POINTS DETAILS</h4>
                                    <p>{member.points}</p>
                                    </div>

                                </div>
                            </div>
                            <div class="voucher-box">
                                <div class="vouchr-title">
                                    <p>YOUR POINTS CAN BE CONVERTED TO</p>
                                </div>
                                <div class="voucher-btn-grp">
                                    <div class="voucer-5x">
                                    <p>{member.vouchers}x</p>
                                    </div>
                                    <div><a ><img src={Images.voucher2} alt="" /></a></div>
                                </div>
                            </div>
                            <div class="convert-voucher-btn">
                                <button className={`trans ${member.vouchers <= 0 ? "disabled" : ""}`} disabled={`${member.vouchers <= 0 ? "disabled" : ""}`} onClick={redeem}><img src={Images.btnconvert} alt="" /></button>
                            </div>
                        </div>}
                        {page == "nonmember" && <div class="member-data">
                            <div class="kiosk-voucher-box">
                                <div class="vouchr-title">
                                    <p>YOU'VE WON</p> 
                                </div>
                                <div class="voucher-btn-grp">
                                    <div class="voucer-5x">
                                    <p>{member.vouchers}x</p>
                                    </div>
                                    <div><a href=""><img src={Images.grp3} alt="" /></a></div>
                                </div>
                            </div>

                            <div class="kiosk-detail-redemption desk-detail">
                                <p>PLEASE PROCEED TO THE L3 CONCIERGE DESK TO COLLECT YOUR VOUCHER (s).</p>               
                                <p>IT IS VALID FOR USE AT JEM
                                WITHIN 2 MONTHS ONLY.</p>
                            </div>

                            <div class="convert-voucher-btn">
                            <a onClick={reset}><img src={Images.donebtn} alt="" /></a>
                            </div>
                        </div>}
                        {page == "member" && <div class="member-data">
                            <div class="kiosk-voucher-box">
                                <div class="vouchr-title">
                                    <p>YOU'VE WON</p>
                                    
                                </div>
                                <div class="voucher-btn-grp">
                                    <div class="voucer-5x">
                                        <p>{rdm}x</p>
                                    </div>
                                    <div><a href=""><img src={Images.grp3} alt="" /></a></div>
                                </div>
                            </div>

                            <div class="kiosk-detail-redemption">
                                <p>THIS VOUCHER (S) WILL BE CREDITED TO YOUR LENDLEASE PLUS WALLET HITHIN 4B HOURS.</p>
                                <p>it is valid for use at jem within 2 months.</p>
                                <p>check your lendlease plus mobile app to view your e-voucher and the expiry date.</p>
                            </div>

                            <div class="convert-voucher-btn">
                            <a onClick={reset}><img src={Images.donebtn} alt="" /></a>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
        </main>
    </>
  );
}

export default Home;
